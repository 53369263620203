.resetButton {
    display: block;
    margin-top: 10px;
}
.resetButton button {
    width: 100%;
    color: var(--white);
    background-color: var(--grey-dark);

    padding: 15px;
    font-size: var(--fs-small);
    font-weight: var(--fw-bold);
    color: var(--white);

    border-radius: 30px;

    transition: 200ms;
}

@media (pointer: fine) {
    .resetButton button:hover {
        background-color: var(--grey-darker);
    }
}
