.scWrapper {
    display: flex;
    justify-content: center;

    width: 250px;
    height: 250px;
}

#scratchContainer {
    width: 100%;
    height: 100%;
}

.sc__container {
    position: relative;
    overflow: hidden;
    height: inherit;
    width: inherit;
}

.sc__inner,
.sc__container canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.sc__container canvas {
    z-index: 2;
}

.scWrapper button {
    position: absolute;
    bottom: 30px;
    z-index: 1;

    width: 150px;
    color: var(--white);
    background-color: var(--grey-dark);

    padding: 15px;
    font-size: var(--fs-small);
    font-weight: var(--fw-bold);
    color: var(--white);

    border-radius: 30px;

    transition: 200ms;
}

@media (pointer: fine) {
    .scWrapper button:hover {
        background-color: var(--grey-darker);
    }
}
