.viewPrivacy h2,
.viewPrivacy h3 {
    font-size: var(--fs-large);
    font-weight: var(--fw-bold);
    line-height: var(--lh-heading);

    margin-top: 20px;
}

.viewPrivacy h3 {
    font-size: var(--fs-regular);
    line-height: var(--lh-heading);
}
.viewPrivacy h3 + h3 {
    margin-top: 10px;
}

.viewPrivacy p {
    font-size: var(--fs-body);
}
.viewPrivacy .content p {
    margin-top: 10px;
}
.viewPrivacy a {
    font-size: var(--fs-body);
}

.viewPrivacy ul {
    padding-left: 20px;
    list-style-type: circle;
}
.viewPrivacy li {
    margin-top: 10px;
    font-size: var(--fs-body);
    list-style-type: circle;
}

.viewPrivacy h3 + h3 {
    margin-top: 10px;
}
