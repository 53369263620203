.card {
    position: absolute;
    z-index: 1;
    border-radius: 20px;
    background-color: var(--white);
    box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 20%);
}

.card:not(.react-draggable-dragged) {
    opacity: 0;
    transform: scale(0) !important;
    transition: opacity 300ms ease, transform 300ms ease;
}
.card.active:not(.react-draggable-dragged) {
    transform: scale(1) !important;
    opacity: 1;
}

.card header {
    position: relative;

    padding: 15px;
    text-align: center;
    background-color: var(--grey);

    cursor: grab;

    border-radius: 20px 20px 0px 0px;
}

.card header p {
    font-size: var(--fs-small);
    font-weight: var(--fw-bold);
    letter-spacing: 0.5px;
}

.card h1 {
    font-size: var(--fs-xxlarge);
    font-weight: var(--fw-bold);
    line-height: var(--lh-body);
}

.card p {
    font-size: var(--fs-large);
    line-height: var(--lh-body);
}
.card a {
    position: relative;
    z-index: 2;

    font-size: var(--fs-large);
    line-height: var(--lh-body);
    font-weight: var(--fw-bold);
}

.card h1 + p {
    margin-top: 10px;
}

.card img {
    width: 100%;
    height: auto;
}

.card header .closeButton {
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -10px;

    width: 20px;
    height: 20px;

    border-radius: 50%;

    background-color: var(--red);
}
.card header .closeButton svg {
    opacity: 0;
    position: absolute;
    top: 2px;
    left: 2px;

    width: 16px;
    height: 16px;
    fill: var(--red-dark);

    opacity: 1;
    transition: opacity 200ms;
}

.card .content {
    padding: 20px;
}

.card .animationContainer {
    opacity: 0;
    transform: scale(0) !important;
    transition: opacity 500ms ease, transform 500ms ease;
}

.card .animationContainer.enter-done {
    opacity: 1;
    transform: scale(1) !important;
    transition: opacity 500ms ease, transform 500ms ease;
}

@media (pointer: fine) {
    .card header .closeButton svg {
        opacity: 0;
    }
    .card header .closeButton:hover svg {
        opacity: 1;
    }

    .card a:hover:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: -5px;
        top: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);

        border-radius: 30px;
        background-color: var(--grey);
    }
}

@media only screen and (max-width: 767px) {
    .card {
        position: relative;
        top: auto;
        left: auto;
        right: auto;

        margin: 10px auto 0px;
    }

    .card header {
        padding: 10px;
    }
}
