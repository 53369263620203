.viewLegal h2,
.viewLegal h3 {
    font-size: var(--fs-large);
    font-weight: var(--fw-bold);
    line-height: var(--lh-heading);
    margin-top: 20px;
}

.viewLegal p {
    font-size: var(--fs-body);
}
.viewLegal .content p {
    margin-top: 10px;
}
.viewLegal a {
    font-size: var(--fs-body);
}
