footer {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 0;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 20px;
}

footer nav {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 40px;
    background-color: var(--white);
    box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 20%);
}

footer a {
    padding: 10px 15px;

    font-size: var(--fs-small);
    font-weight: var(--fw-bold);
    color: var(--black);

    border-radius: 30px;
    transition: 200ms background-color;
}

footer a.active {
    background-color: var(--grey-dark);
}
footer a.active p {
    color: var(--white);
}

@media (pointer: fine) {
    footer a:hover {
        background-color: var(--grey-dark);
    }
    footer a:hover p {
        color: var(--white);
    }
}

@media only screen and (max-width: 767px) {
    footer a {
        padding: 5px 10px;
        font-size: var(--fs-small);
    }
    footer {
    }
}

@media only screen and (max-width: 450px) {
    footer {
        z-index: 20;
    }
}
