img {
    cursor: pointer;
}

.imageDarkmodeContainer {
    position: relative;
    cursor: pointer;
}
.imageDarkmodeContainer img:first-child {
    display: block;
}
.imageDarkmodeContainer img:last-child {
    display: none;
}

[data-theme="dark"] .imageDarkmodeContainer img:first-child {
    display: none;
}
[data-theme="dark"] .imageDarkmodeContainer img:last-child {
    display: block;
}
