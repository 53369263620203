:root {
    --grey: #ebebeb;
    --grey-dark: #505050;
    --grey-darker: #3c3c3c;
    --white: #fff;
    --black: #000;
    --red: #ff4141;
    --red-dark: #c21616;

    --background: #86f793;

    --fs-small: 0.8rem;
    --fs-body: 1rem;
    --fs-regular: 1.3rem;
    --fs-large: 1.5rem;
    --fs-xlarge: 2rem;
    --fs-xxlarge: 2.7rem;

    --fw-thin: 200;
    --fw-regular: 400;
    --fw-bold: 600;

    --lh-body: 1.5;
    --lh-heading: 1.3;

    --background-black: #5e5e5e, #2b2b2b, #000000;
    --background-white: #ffffff, #ffffff, #9b9b9b;
    --background-green: #e1ffe4, #86f793, #86f793;
    --background-pink: #ffc4bc, #ffc4bc, #ff9189;
    --background-purple: #efe5fc, #b7affc, #b1a7ff;
}

[data-theme="dark"] {
    --grey: #383838;
    --grey-dark: #777777;
    --grey-darker: #949494;
    --white: #212121;
    --black: #fff;
}

[data-color="black"] .background {
    --palette: var(--background-black);
}
[data-color="white"] .background {
    --palette: var(--background-white);
}
[data-color="green"] .background {
    --palette: var(--background-green);
}
[data-color="pink"] .background {
    --palette: var(--background-pink);
}
[data-color="purple"] .background {
    --palette: var(--background-purple);
}

.background {
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: fixed;
    top: 0px;
    left: 0px;

    display: grid;
    place-content: center;
    grid-template-areas: gradientRotation;

    --rotate: 90deg;
    --palette: #e1ffe4, #86f793, #86f793;
}

.background::before,
.background::after {
    --size: 150vmax;
    --duration: 20s;

    content: "";
    grid-area: gradientRotation;
    width: var(--size);
    height: var(--size);
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    z-index: -1;
}

.background::before {
    animation-name: rotate;
    animation-duration: var(--duration);
    background: linear-gradient(to left, var(--palette));
}

.background::after {
    animation-name: rotate, fade;
    animation-duration: calc(var(--duration) / 2), var(--duration);
    background: linear-gradient(to right, var(--palette));
}

@keyframes rotate {
    to {
        transform: rotate(1turn);
    }
}

@keyframes fade {
    50% {
        opacity: 0;
    }
}

@media only screen and (max-width: 767px) {
    :root {
        --fs-small: 0.8rem;
        --fs-body: 0.8rem;
        --fs-regular: 1rem;
        --fs-large: 1.2rem;
        --fs-xlarge: 1.5rem;
        --fs-xxlarge: 2rem;
    }
}

body,
html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
p,
a,
label,
input,
li {
    font-family: "Poppins", sans-serif;
    color: var(--black);
}

::-moz-selection {
    color: #000;
    background: #beffbd;
}

::selection {
    color: #000;
    background: #beffbd;
}

@media only screen and (max-width: 767px) {
    body,
    html {
        overflow: auto;
    }
}
