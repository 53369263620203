/*COOKIE SCRIPT*/

.cc_dialog .cc_dialog_headline {
    font-size: 18px !important;
}
.cc_dialog .cc_dialog_text {
    font-size: 14px !important;
}

.cc_dialog {
    box-sizing: border-box;

    left: 15px !important;
    bottom: 15px !important;

    width: calc(100% - 30px) !important;
    max-width: calc(100% - 30px) !important;

    padding: 20px !important;

    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

.cc_dialog button {
    display: inline-block !important;
}
.cc_dialog button + button {
    margin-left: 10px !important;
}

.cc_dialog div + div {
    margin-top: 20px !important;
}

.cookie-consent-preferences-dialog .cc_cp_head_title > p,
.cookie-consent-preferences-dialog .cc_cp_head_lang_selector,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_footer
    .cc_cp_f_powered_by {
    display: none !important;
}

.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_head,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_footer {
    box-sizing: border-box;
    padding: 10px !important;
}

.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_head
    .cc_cp_head_title {
    padding-left: 0px !important;
}

/*COLORS */

.cc_dialog {
    background-color: #fff !important;
}
.cc_dialog h1,
.cc_dialog p {
    color: #000 !important;
}

.cc_dialog .cc_b_ok,
.cc_cp_f_save button {
    background-color: #86f793 !important;
    color: #000 !important;
}

.cookie-consent-preferences-dialog .cc_cp_container {
    background: #fff !important;
}
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_head,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_content,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_footer,
.dark.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_head,
.dark.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_content,
.dark.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_footer
    .light.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_head,
.light.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_content,
.light.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_footer {
    background: #fff !important;
}

.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_footer,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu,
.dark.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_footer,
.dark.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu,
.light.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_footer,
.light.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu {
    border-top-color: #eee !important;
}

.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu
    li[active="true"],
.dark.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu
    li[active="true"],
.light.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu
    li[active="true"] {
    background: #000 !important;
}

.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu
    li[active="true"]
    a,
.dark.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu
    li[active="true"]
    a,
.light.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    .cc_cp_content
    .cc_cp_m_menu
    li[active="true"]
    a {
    color: #fff !important;
}

.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    h1,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    h2,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    h3,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    h4,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    p,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    label,
.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    a {
    color: #000 !important;
}

.cookie-consent-preferences-overlay
    .cookie-consent-preferences-dialog
    .cc_cp_container
    p
    a {
    text-decoration: underline;
}
