.languageSwitcher {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
}

.languageSwitcher input {
    display: none;
}
.languageSwitcher label {
    display: block;
    position: relative;

    width: 90px;
    height: 50px;

    cursor: pointer;
    border-radius: 25px;
    background-color: var(--grey);
}

.languageSwitcher label:before {
    box-sizing: border-box;
    display: block;

    content: "EN";
    font-size: var(--fs-small);
    font-weight: var(--fw-bold);
    color: var(--white);

    padding: 14px 12px;

    position: absolute;
    left: 5px;
    top: 5px;

    width: 40px;
    height: 40px;

    border-radius: 50%;

    background-color: var(--grey-dark);
    transition: 200ms;
}
.languageSwitcher input:checked + label:before {
    content: "DE";
    left: 45px;
}
