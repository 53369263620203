.loaderOverlay {
    position: fixed;
    top: 50%;
    margin-top: -50px;
    left: 50%;
    margin-left: -50px;

    width: 100px;
    height: 100px;

    border-radius: 20px;
    background-color: var(--white);
    box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 20%);
}

@media only screen and (max-width: 767px) {
    .viewHome {
        padding-bottom: 100px;
    }
}
