.colorSwitcher {
    display: flex;
    align-self: center;
    gap: 10px;
    margin-top: 10px;
}

.colorSwitcher button {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}
.colorSwitcher button.active {
    border: 3px solid var(--grey-dark);
}

.colorSwitcher #black {
    background: linear-gradient(135deg, var(--background-black));
}
.colorSwitcher #white {
    background: linear-gradient(135deg, var(--background-white));
}
.colorSwitcher #green {
    background: linear-gradient(135deg, var(--background-green));
}
.colorSwitcher #pink {
    background: linear-gradient(135deg, var(--background-pink));
}
.colorSwitcher #purple {
    background: linear-gradient(135deg, var(--background-purple));
}
