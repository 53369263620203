.themeSwitcher {
    display: inline-block;
    vertical-align: middle;
}

.themeSwitcher input {
    display: none;
}
.themeSwitcher label {
    display: block;
    position: relative;

    width: 90px;
    height: 50px;

    cursor: pointer;
    border-radius: 25px;
    background-color: var(--grey);
}

.themeSwitcher label:before {
    box-sizing: border-box;
    display: block;

    content: "🌞";
    font-size: 2.5rem;

    padding: 1px 0px;

    position: absolute;
    left: 5px;
    top: 5px;

    width: 40px;
    height: 40px;

    border-radius: 50%;

    transition: 200ms;
}
.themeSwitcher input:checked + label:before {
    content: "🌚";
    left: 45px;
}
